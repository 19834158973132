import { helpers } from 'vuelidate/lib/validators'

const numberCount = regexCountValidator(new RegExp('\\d', 'g'))

const upperCaseCount = regexCountValidator(new RegExp('[A-Z]', 'g'))

const nonAlphaNumericCount = regexCountValidator(new RegExp('[^\\w\\d]', 'g'))

const regex = (regex) => {
  return (value) => {
    // We ain't here to check if it's required just if it matches the regex
    if (typeof value === 'undefined' || value === null || value === '') {
      return true
    }
    return regex.test(value)
  }
}

function regexCountValidator (regex) {
  return (count) => (value) => {
    if (!helpers.req(value)) {
      return false
    }
    if (!count) {
      return regex.test(value)
    } else {
      var matchResult = value.match(regex)
      return matchResult ? matchResult.length >= count : false
    }
  }
}
// Test for import
export { numberCount, upperCaseCount, nonAlphaNumericCount, regex }
